import { gql } from 'apollo-boost'

const CartFragment = gql`
  fragment CartFragment on OrderInterface {
    number
    itemSubtotal
    totalPrice
    totalDiscount
    totalTax
    totalTaxIncluded
    totalShippingCost
    shippingAddress {
      id
      addressLine1
      addressLine2
      administrativeArea
      countryCode
      fullName
      locality
      postalCode
    }
    billingAddress {
      id
      addressLine1
      addressLine2
      administrativeArea
      countryCode
      fullName
      locality
      postalCode
    }
    billingSameAsShipping
    items: lineItems {
      id
      price
      subtotal
      total
      tax
      taxIncluded
      qty
      purchasable {
        title
        price
        sku
        product {
          id
          title
          ... on incontinenceProducts_Product {
            customProductTitle
            title
            uri
            volume
            packSize
            gender {
              title
            }
            variants {
              id
              title
              price
              sku
            }
            productType {
              title
            }
            gallery: listingGallery(limit:1) {
              url
            }
          }
          ... on inserts_Product {
            customProductTitle
            title
            uri
            volume
            packSize
            gender {
              title
            }
            variants {
              id
              title
              price
              sku
            }
            productType {
              title
            }
            gallery: listingGallery(limit:1) {
              url
            }
          }
        }
      }
    }
  }
`

export default CartFragment
